import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import {
  IncidentDetailsDTO,
  IncidentFormDTO,
  IncidentListDTO,
} from '../dto/incident.dto';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import {
  convertFormToFileFormDTOArray,
  convertResponseToFileDetailsDTO,
} from '../../../../shared/dto/file.dto';
import { convertResponseToUserMinimalDTO } from '../../../organization/users/dto/user.dto';
import { convertResponceToIncidentCategoryDetailsDTO } from '../../../settings/incident/dto/incident-category.dto';
import { convertResponceToIncidentSubCategoryDetailsDTO } from '../../../settings/incident/dto/incident-sub-category.dto';
import { convertResponceToIncidentTypeDetailsDTO } from '../../../settings/incident/dto/incident-type.dto';
import { convertResponseToSeverityLevelDetailsDTO } from '../../../settings/masters/dto/severity-level.dto';
import { convertResponceToIncidentStatusDetailsDTO } from '../../../settings/incident/dto/incident-status.dto';
import { ControlConversion } from '../../../organization/controls/conversion/control.conversion';
import { RiskRegisterConversion } from '../../../risk/risk-register/conversion/risk-register.conversion';
import { ProcessConversion } from '../../../organization/process/conversion/process.conversion';

@Injectable({
  providedIn: 'root',
})
export class IncidentConversion
  implements
    MainConversionInterface<
      IncidentListDTO,
      IncidentFormDTO,
      IncidentDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private controlConversion: ControlConversion,
    private riskRegisterConversion: RiskRegisterConversion,
    private processConversion: ProcessConversion,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): IncidentListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        title: item.title,
        description: item.description,
        incident_at: item.incident_at,
        location: item.location,
        reported_at: item.reported_at,
        quick_action: item.quick_action,
        incident_category_id: item.incident_category_id,
        incident_sub_category_id: item.incident_sub_category_id,
        incident_type_id: item.incident_type_id,
        responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'responsible_user'
        ),
        incident_status: this.tableConv.resToColordItem(item, 'incident_status'),
        severity_level: this.tableConv.resToColordItem(item, 'severity_level'),
        reported_user: this.userConv.resToUserMinimalDTO(item, 'reported_user'),
        incident_category_title: item[`incident_category_title_${this.lang}`],
        incident_sub_category_title:
          item[`incident_sub_category_title_${this.lang}`],
        incident_type_title: item[`incident_type_title_${this.lang}`],
        created_at: item.created_at,
        updated_at: item.updated_at,
        created_user_first_name: item[`created_user_first_name_${this.lang}`],
        created_user_last_name: item[`created_user_last_name_${this.lang}`],
        updated_user_first_name: item[`updated_user_first_name_${this.lang}`],
        updated_user_last_name: item[`updated_user_last_name_${this.lang}`],
      };
    });
    return result;
  }
  formGroupToForm(formGroup: FormGroup): IncidentFormDTO {
    return {
      id: parseInt(formGroup.get('Id')?.value),
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      incident_at: formGroup.get('IncidentAt')?.value,
      incident_type_id: parseInt(formGroup.get('IncidentType')?.value),
      incident_category_id: parseInt(formGroup.get('Category')?.value),
      incident_sub_category_id: parseInt(formGroup.get('SubCategory')?.value),
      severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value),
      location: formGroup.get('Location')?.value,
      documents: convertFormToFileFormDTOArray(formGroup),
      reported_by: parseInt(formGroup.get('ReportedBy')?.value),
      responsible_user_id: parseInt(formGroup.get('ResponsibleUser')?.value),
      reported_at: formGroup.get('ReportedAt')?.value,
      quick_action: formGroup.get('ActionDetails')?.value,
      incident_status_id: parseInt(formGroup.get('CurrentStatus')?.value),
      process_ids: formGroup.get('Process')?.value,
      control_ids: formGroup.get('Controls')?.value || [],
      compliance_requirement_ids:
        formGroup.get('ComplianceRequirement')?.value || [],
      risk_ids: formGroup.get('Risk')?.value || [],
    };
  }
  resToForm(response: any): any {
    return {
      Id: response.id,
      Title: response.title,
      Description: response.description,
      IncidentAt: response.incident_at,
      IncidentType: response.incident_type.id,
      Category: response.incident_category.id,
      SubCategory: response.incident_sub_category?.id,
      SeverityLevel: response.severity_level.id,
      Location: response.location,
      Documents: response.documents,
      ReportedBy: response.reported_by.id,
      ResponsibleUser: response.responsible_user?.id,
      ReportedAt: response.reported_at,
      ActionDetails: response.quick_action,
      CurrentStatus: response.incident_status.id,
      Process: response.processes?.map((e: any) => e.process_id),
      Controls: response.controls?.map((e: any) => e.control_id),
      ComplianceRequirement: response.compliance_requirements?.map(
        (e: any) => e.compliance_requirement_id
      ),
      Risk: response.risks?.map((e: any) => e.risk_register_id),
    };
  }
  resToDetails(response: any): IncidentDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      title: response.title,
      description: response.description,
      incident_at: response.incident_at,
      location: response.location,
      reported_at: response.reported_at,
      quick_action: response.quick_action,
      incident_category: convertResponceToIncidentCategoryDetailsDTO(
        response.incident_category,
        this.lang
      ),
      incident_sub_category: convertResponceToIncidentSubCategoryDetailsDTO(
        response.incident_sub_category,
        this.lang
      ),
      incident_type: convertResponceToIncidentTypeDetailsDTO(
        response.incident_type,
        this.lang
      ),
      severity_level: convertResponseToSeverityLevelDetailsDTO(
        response.severity_level,
        this.lang
      ),
      incident_status: convertResponceToIncidentStatusDetailsDTO(
        response.incident_status,
        this.lang
      ),
      reported_by: convertResponseToUserMinimalDTO(
        response.reported_by,
        this.lang
      ),
      responsible_user: convertResponseToUserMinimalDTO(
        response.responsible_user,
        this.lang
      ),
      documents: response.documents?.map((doc: any) =>
        convertResponseToFileDetailsDTO(doc)
      ),
      created_at: response.created_at,
      updated_at: response.updated_at,
      created_by: convertResponseToUserMinimalDTO(
        response.created_by,
        this.lang
      ),
      updated_by: convertResponseToUserMinimalDTO(
        response.updated_by,
        this.lang
      ),
    };
  }
}
